.spinner-container {
  display: inline-block;
  width: 128px;
  height: 128px;
  position: relative;
}

.spinner-orbit-path {
  animation: spin-container 4s linear infinite;
  transform-origin: 64px 64px;
}

.spinner-inner-circle, .spinner-outer-circle {
  transform-origin: 64px 64px;
}

.spinner-inner-circle {
  animation: self-spin-right 2s linear infinite;
}

.spinner-outer-circle {
  animation: self-spin-left 3s linear infinite;
}

.spinner-central-dot {
  animation: pulse 1.5s ease-in-out infinite;
  transform-origin: 64px 64px;
}

@keyframes spin-container {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes self-spin-left {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes self-spin-right {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}
